/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const LINK_BUTTONS = [
  {
    linkText: "Roof Inspections",
    linkURL: "/roof-maintenance/roof-inspections",
  },
  {
    linkText: "Roof Cleaning",
    linkURL: "/roof-maintenance/roof-cleaning",
  },
  {
    linkText: "Roof Coating",
    linkURL: "/roof-maintenance/roof-coating",
  },
];

const RoofMaintenance = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="roof maintenance"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerBottomIconAlt="Icon with blue roof tops"
      headerBottomIconSrc={data.RoofTopsIcon.publicURL}
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Roof Maintenance" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Man cleaning a roof with a pressure washer."
            imageURL={data.MaintenanceServices.childImageSharp.gatsbyImageData}
            linkArr={LINK_BUTTONS}
            linkArrTitle="Roof Maintenance we offer"
            linksEnabled={true}
            titleText="Roof Maintenance Services"
          >
            <p>
              Roof Maintenance should be a regular part of maintaining your
              home. Regardless of the material, eventually you’ll want to
              inspect the roof for unseen problems and clean everything up to
              ensure that issues do not arise from debris or dirt buildup. Roofs
              also benefit from inspections to ensure there are no severe
              impacts from hail or wind.
            </p>

            <p sx={{ mb: 6 }}>
              Legacy Repair specializes in roof repairs and maintenance. Our
              technicians can reseal penetrations, clean and prepare fascia,
              remove and replace old or worn material and check for any
              potential issues. Arizona may see a lot of sun, but when the
              weather does strike, it strikes hard and sure. Be prepared and
              contact us today!
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "maintenance-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    RoofTopsIcon: file(relativePath: { eq: "roof-tops.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    MaintenanceServices: file(
      relativePath: { eq: "maintenance-services.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default RoofMaintenance;
